<template>
  <div class="collect-list">
    <div class="go-back">
      <span @click="prev">{{ i18n.Word.toHome }}</span>
    </div>
    <div class="body">
      <div class="category-list">
        <div class="title">{{ i18n.Word.MyFavorite }}</div>
        <div class="list">
          <div
            v-for="item of categoryList"
            :key="item.id"
            :class="{ active: item.id === category.id }"
            @click="handleSelect(item)"
          >
            <div class="info">
              <i class="iconfont icon-shoucangjia"></i>
              <span>{{ item.name }}</span>
            </div>
            <el-dropdown
              v-if="item.id !== 1"
              @command="(command) => handleCommand(command, item)"
            >
              <i class="iconfont icon-more"></i>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="rename">{{
                  i18n.Word.rename
                }}</el-dropdown-item>
                <el-dropdown-item command="delete">{{
                  i18n.Word.delete
                }}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <div class="add-group" v-if="categoryList.length < 6">
          <div class="line"></div>
          <el-input
            maxlength="6"
            v-model="addIpt"
            :prefix-icon="activeAdd ? '' : 'iconfont icon-guanbi'"
            :placeholder="activeAdd ? i18n.Word.UpTo : i18n.Word.AddGroup"
            @focus="activeAdd = true"
            @blur="handleIptBlur"
          >
            <el-button
              v-if="activeAdd"
              slot="append"
              type="primary"
              @click="handleSaveGroup"
              style="height: 40px !important"
            >
              {{ i18n.Word.determine }}
            </el-button>
          </el-input>
        </div>
      </div>

      <div class="content-list">
        <div
          class="collect-list-content"
          :class="{ hasList: list && list.length }"
        >
          <div class="collect-list-header">
            <h3>{{ i18n.Word.Favorites }}({{ pagination.total }})</h3>
          </div>
          <div class="list" v-loading="loading">
            <template v-if="list && list.length">
              <div v-for="(item, index) of list" :key="item.id">
                <a class="collect-list-text" :href="jump(item)">
                  <span>
                    {{
                      (pagination.currentPage - 1) * pagination.pageSize +
                        index +
                        1
                    }}.
                  </span>
                  <div class="name">{{ item.title }}</div>
                  <div class="tag">#{{ item.paramerid }}</div>
                  <div class="tag type">{{ item.tag_name }}</div>
                </a>
                <i
                  class="f-icon icon-star-c btn-item-mark active"
                  @click.stop="handleRemove(item)"
                ></i>
              </div>
            </template>
          </div>
          <Empty v-if="!loading && (!list || !list.length)" />
        </div>
        <el-pagination
          v-if="list && list.length"
          small
          layout="prev, pager, next"
          class="collect-list-pagination"
          @current-change="fetchList"
          :page-count="pagination.pageCount"
          :page-size="pagination.pageSize"
          :current-page.sync="pagination.currentPage"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {
  apiMyCollect,
  apiAddCollect,
  apiMyCollectCategory,
  apiDelMyCollect,
  apiRenameMyCollect,
  apiWebCollect
} from '@/api/api.js'
import Empty from '@/components/Empty'

export default {
  components: {
    Empty
  },
  computed: {
    i18n() {
      return this.$t('messages')
    }
  },
  data() {
    return {
      categoryList: [],
      category: null,
      activeAdd: false,
      addIpt: '',

      list: [],
      pagination: {
        pageCount: 0,
        currentPage: 1,
        pageSize: 10,
        total: 0
      },
      loading: false
    }
  },
  created() {
    this.fetchCategoryList()
  },
  methods: {
    prev() {
      this.$router.push({ name: 'home' })
    },
    async fetchCategoryList() {
      const { data: list } = await apiMyCollectCategory()
      this.categoryList = list
      this.category = list[0]

      this.pagination.currentPage = 1
      this.fetchList()
    },
    fetchList() {
      if (this.loading) {
        return
      }

      this.loading = true
      apiMyCollect({
        page: this.pagination.currentPage,
        pageNum: this.pagination.pageSize,
        cate_id: this.category.id
      })
        .then((res) => {
          if (res.code !== 200) {
            return
          }
          this.list = res.data.list
          this.pagination.pageCount = res.data.count_page
          this.pagination.total = res.data.total
        })
        .finally(() => {
          this.loading = false
        })
    },
    jump(data) {
      if (data.type == 'practice') {
        return this.$router.resolve({
          name: 'Exercises',
          query: {
            clid: data.clid,
            practiceId: data.paramerid,
            from: 'collect'
          }
        }).href
      }
      if (data.type == 'gremat') {
        return this.$router.resolve({
          name: 'Exercises',
          query: { clid: data.clid, greId: data.paramerid, from: 'collect' }
        }).href
      }
    },

    async handleSaveGroup() {
      const { msg } = await apiAddCollect({
        name: this.addIpt
      })
      this.$message.success(msg)
      this.addIpt = ''

      this.fetchCategoryList()
    },
    handleIptBlur() {
      setTimeout(() => {
        this.activeAdd = false
      }, 500)
    },

    handleSelect(item) {
      this.category = item

      this.pagination.currentPage = 1
      this.fetchList()
    },
    handleCommand(command, item) {
      if (command === 'delete') {
        this.deleteSave(item)
      } else if (command === 'rename') {
        this.renameSave(item)
      }
    },

    async deleteSave(item) {
      await this.$confirm(this.$t('Word.delTip'), this.$t('Word.deleteTitle'), {
        confirmButtonText: this.$t('Word.determine'),
        cancelButtonText: this.$t('Word.cancel')
      })

      const { msg } = await apiDelMyCollect({
        id: item.id
      })
      this.$message.success(msg)

      this.fetchCategoryList()
    },
    async renameSave(item) {
      const { value } = await this.$prompt('', this.$t('Word.renameTitle'), {
        confirmButtonText: this.$t('Word.modify'),
        cancelButtonText: this.$t('Word.cancel'),
        inputPlaceholder: this.$t('Word.UpTo'),
        inputErrorMessage: this.$t('Word.UpTo'),
        inputValue: item.name,
        inputValidator: (val) => {
          return val.length < 7
        }
      })

      const { msg } = await apiRenameMyCollect({
        id: item.id,
        name: value
      })
      this.$message.success(msg)

      this.fetchCategoryList()
    },
    async handleRemove(item) {
      const { code, msg } = await apiWebCollect({
        aid: item.paramerid,
        paramerid: item.paramerid,
        cate_id: item.cate_id,
        type: item.type
      })
      if (code != 200) {
        return this.$message.error(msg)
      }

      this.$message.success(msg)
      this.fetchCategoryList()
    }
  }
}
</script>

<style lang="scss">
.collect-list {
  .btn-item-mark {
    color: #ffc06a;
  }
  .tag {
    padding: 0 13px;
    height: 20px;
    line-height: 20px;
    background: #ebebf2;
    border-radius: 15px;
    text-align: center;
    font-size: 12px;
    color: #a4afb7;
    margin-right: 10px;
    &.type {
      background: #c1e6fe;
      color: #345d7f;
    }
  }
  .go-back {
    color: #3b86ff;
    padding: 24px 0 20px;
    font-weight: 400;
    font-size: 14px;
    span {
      display: inline-block;
      cursor: pointer;
      &::before {
        content: '<';
        margin-right: 10px;
      }
    }
  }
  .body {
    display: flex;
  }
  .category-list {
    width: 260px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    .title {
      padding: 28px 33px;
      font-size: 20px;
      color: #1f1f40;
      font-weight: bold;
      border-bottom: 1px solid #dcdcdc;
    }
    .list {
      padding: 10px 33px 20px;
      > div {
        cursor: pointer;
        margin-top: 20px;
        display: flex;
        align-items: center;
        &.active {
          color: #3b86ff;
          .info {
            > span {
              color: #3b86ff;
            }
          }
        }
        .info {
          flex: 1;
          display: flex;
          align-items: center;
          .icon-shoucangjia {
            font-size: 15px;
            width: 30px;
          }
          > span {
            font-size: 16px;
            color: #1f1f40;
          }
        }
        .icon-more {
          font-size: 12px;
          cursor: pointer;
        }
      }
    }
    .add-group {
      padding: 0 33px 50px;

      .el-input__inner {
        &:focus {
          border-color: #3b86ff !important;
        }
      }

      .line {
        height: 1px;
        width: 100%;
        background-color: #ebebf2;
        margin-bottom: 26px;
      }
      .el-button {
        background-color: #c9e2ff;
        color: #3b86ff;
      }
    }
  }
  .content-list {
    flex: 1;
    margin-left: 42px;
  }
  .collect-list-content {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    &.hasList {
      min-height: 780px;
    }
    .list {
      padding: 0 40px;
      > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 24px 0;
        color: #1f1f40;
        font-size: 14px;
        border-bottom: 1px solid #ebebf2;
        cursor: pointer;
        &:last-child {
          border: none;
        }
        .name {
          margin: 0 17px 0 5px;
        }
        .collect-list-text {
          display: flex;
          text-decoration: none;
          color: #1f1f40;
          font-size: 14px;
        }
      }
    }
  }
  .collect-list-header {
    display: flex;
    padding: 0 40px;
    height: 90px;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  }
  .collect-list-pagination {
    text-align: center;
    margin-top: 15px;
    &.el-pagination--small {
      .el-pager li,
      .btn-prev,
      .btn-next {
        border: 1px solid rgba(199, 202, 222, 1);
        border-radius: 3px;
        margin: 0 3px;
        color: #c7cade;
      }
      .el-pager li.active {
        border: 1px solid rgba(59, 134, 255, 1);
        color: #3b86ff;
      }
      .btn-prev,
      .btn-next {
        padding: 0 4px;
      }
      .el-pager li.btn-quicknext,
      .el-pager li.btn-quickprev {
        border: none;
      }
    }
  }
}
</style>
